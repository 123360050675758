import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-loader',
  standalone: true,
  imports: [],
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.scss',
})
export class LoaderComponent {
  @Input() color = '#6f9305';

  @HostBinding('style.position') @Input() position = 'relative';
  @HostBinding('style.border') border = `3px solid ${this.color}`;
  @HostBinding('style.borderRightColor') borderRightColor = 'transparent';
}
