import { Component, input } from '@angular/core';

import { LoaderComponent } from '../loader/loader.component';

@Component({
  selector: 'app-loader-global',
  standalone: true,
  imports: [LoaderComponent],
  templateUrl: './loader-global.component.html',
  styleUrl: './loader-global.component.scss',
})
export class LoaderGlobalComponent {
  isInfinite = input<boolean>(false);
}
